/* @import '../../node_modules/modern-normalize/modern-normalize.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply font-bold py-2 px-4 rounded;
}

html,
body {
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.sticky-nav {
  @apply fixed top-[0px] rounded-lg left-[0px] w-[100%] shadow-md z-50;
  transition: all .5s ease;
}
.height-root-content{
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}
.normal-nav {
  @apply w-[100%];
  transition: all .5s ease;
}

::-webkit-scrollbar {
  width: 10px;
  /* display: none; */
}

::-webkit-scrollbar:focus {
  display: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCC;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@layer base {
  
  h1 {
    @apply text-3xl font-bold md:text-3xl;
  }
  
  h2 {
    @apply text-2xl font-bold md:text-2xl;
  }
  
  h3 {
    @apply text-xl font-bold md:text-xl;
  }
  
  h4 {
    @apply text-lg font-bold md:text-lg;
  }
  
  body {
    @apply text-base md:text-base;
  }
}
@layer utilities {
  .bg-gradient-primary {
    @apply bg-gradient-to-r from-sidebar from-20% to-sidebar-300 to-100%;
  }
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }

  }
  .animate-shake {
    animation: shake 0.5s infinite;
  }
}

.cust-container {
  @apply  mnlaptop:w-[70%] lg:max-mxmobile:w-[90%] lg:mx-auto m-0 md:pb-[50px] px-3 w-full;
}